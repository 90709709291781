import { Shortcuts } from 'designsystem/components/3-organisms/Shortcuts';
import { LinkFeature } from 'features/Modules/Link';
interface ShortCutsFeatureProps extends Content.Shortcuts {
	className?: string;
}
export const ShortcutsFeature: React.FC<ShortCutsFeatureProps> = ({ content, settings, className }) => {
	const { heading, shortcutCards } = content?.properties ?? {};
	const { hideHeading, noTopMargin } = settings?.properties ?? {};
	const shortcuts = shortcutCards.map((card) => {
		const shortcut = card?.content?.properties;
		const { image: oldImage, picture } = shortcut ?? {};
		const imageObj = picture?.length > 0 ? picture[0]?.content?.properties.image : oldImage;
		const { url, focalPoint } = imageObj ?? {};
		const altText = picture?.length > 0 ? picture[0]?.content?.properties.altText : imageObj?.properties?.altText;
		const altTextChecked = altText == '""' ? '' : altText;
		return {
			...shortcut,
			image: {
				altText: altTextChecked,
				url,
				focalPoint,
			},
			link: <LinkFeature {...shortcut?.link} />,
			linkText: shortcut?.link?.name,
		};
	});
	return <Shortcuts className={className} heading={heading} noTopMargin={noTopMargin} hideHeading={hideHeading} shortcutCards={shortcuts} />;
};
