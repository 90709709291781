import classNames from 'classnames';
import { Heading } from 'components/1-atoms/Heading';
import { ShortcutCard, ShortcutCardProps } from 'components/2-molecules/Card';
import styles from './Shortcuts.module.scss';

export interface ShortcutsProps {
	className?: string;
	heading: string;
	hideHeading?: boolean;
	noTopMargin?: boolean;
	shortcutCards: ShortcutCardProps[];
}

export const Shortcuts: React.FC<ShortcutsProps> = ({ className, heading, hideHeading = false, noTopMargin = false, shortcutCards }) => (
	<div className={classNames(styles.Shortcuts, { [styles.Shortcuts___noTopMargin]: noTopMargin }, className)}>
		<div className={styles.Shortcuts_wrapper}>
			<Heading headingLevel="h2" style="md" className={classNames(styles.Shortcuts_heading, hideHeading && styles.Shortcuts_heading___hidden)}>
				{heading}
			</Heading>
			<div className={styles.Shortcuts_links}>
				{shortcutCards.map((s, index) => {
					s.image.sizes = shortcutCards.length >= 6 ? `240px` : `480px`;
					return <ShortcutCard className={styles.Shortcuts_card} key={index} {...s} />;
				})}
			</div>
		</div>
	</div>
);
